import React from "react";
import { Helmet } from "react-helmet";
import { stylesConfig } from "../../helpers/stylesConfig";

const WebHelmet = () => {
  const canonicalURL = `https://ad.911play.live/`;
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "911win trò chơi miễn phí",
    alternateName: "911win",
    url: "https://69boom.com",
    mainEntityOfPage: "https://ad.911play.live/",
    logo: "https://ad.911play.live/static/media/911BOLD.8f610be2a7d423379db1.webp",
    description: "911win Trò chơi miễn phí,baccarat là gì,soi kèo bet",
    sameAs: [
      "https://www.facebook.com/top.911win/",
      "https://twitter.com/911winCo",
      "https://medium.com/@xoso911win",
      "https://www.google.com/search?q=911win",
    ],
  };

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>911win đăng nhập - đánh baccarat trực tuyến</title>
      <meta
        name="description"
        content="911win Trò chơi miễn phí,baccarat là gì,soi kèo bet"
      />
      <meta content="website" property="og:type" />
      <meta
        property="og:title"
        content="911win đăng nhập - đánh baccarat trực tuyến"
      />
      <meta
        property="og:description"
        content="911win Trò chơi miễn phí,baccarat là gì,soi kèo bet"
      />
      <meta property="og:image" content={stylesConfig.loggedInLogo} />
      <meta property="og:url" content="https://ad.911play.live/" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=0"
      ></meta>
      <link rel="icon" href={stylesConfig.loggedInLogo} />
      <link rel="canonical" href={canonicalURL} />
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
    </Helmet>
  );
};

export default WebHelmet;
