import React, { useState, useEffect, useRef } from "react";
import { Box, Image, keyframes, useColorMode } from "@chakra-ui/react";
import LazyImage from "./lazyImage";
import { useTheme } from "../utils/themeContext";

// 旋轉動畫
const rotate = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

const BlockOne = ({ data }) => {
  const { colorMode } = useColorMode();
  const { theme } = useTheme();
  const [poster, setPoster] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (!data || !data.data) return;

    const { hash, tag5_pic0 } = data.data;
    const filePath = `/UserFiles/${hash}/${tag5_pic0}`;
    const fileExtension = tag5_pic0.split(".").pop().toLowerCase();
    const isVideo = ["mp4", "mov", "avi", "webm"].includes(fileExtension);

    if (isVideo) {
      const video = document.createElement("video");
      video.crossOrigin = "anonymous"; // 如果影片來是不同網域
      video.src = filePath;
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        video.currentTime = 1; // 設到影片的第一秒
      };

      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        setPoster(canvas.toDataURL());
      };
    }
  }, [data]);

  if (!data || !data.data) return null;

  const { hash, tag5_pic0 } = data.data;
  const filePath = `/UserFiles/${hash}/${tag5_pic0}`;
  const fileExtension = tag5_pic0.split(".").pop().toLowerCase();
  const isVideo = ["mp4", "mov", "avi", "webm"].includes(fileExtension);

  return (
    <Box
      position="relative"
      width="90%"
      maxWidth="600px"
      margin="auto"
      overflow="hidden"
      marginTop="50px"
      h="300px"
      borderRadius="12px"
      _before={{
        content: '""',
        position: "absolute",
        zIndex: -2,
        left: "-50%",
        top: "-50%",
        width: "200%",
        height: "200%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "50% 50%, 50% 50%",
        backgroundPosition: "0 0, 100% 0, 100% 100%, 0 100%",
        backgroundImage: `linear-gradient(${theme.colors.primary[400]}, ${theme.colors.primary[500]}), 
                          linear-gradient(${theme.colors.primary[300]}, ${theme.colors.primary[300]}), 
                          linear-gradient(${theme.colors.primary[700]}, ${theme.colors.primary[700]}), 
                          linear-gradient(${theme.colors.primary[200]}, ${theme.colors.primary[200]})`,
        animation: `${rotate} 5s linear infinite`,
      }}
      _after={{
        content: '""',
        position: "absolute",
        zIndex: -1,
        left: "3px",
        top: "3px",
        width: "calc(100% - 4px)",
        height: "calc(100% - 4px)",
        background: "black",
        borderRadius: "12px",
      }}
    >
      <Box
        as="div"
        position="absolute"
        paddingTop="1%"
        top="15px"
        left="15px"
        right="15px"
        bottom="15px"
      >
        {isVideo ? (
          <video
            ref={videoRef}
            src={filePath}
            poster={poster}
            controls
            preload="metadata"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          <LazyImage
            src={filePath}
            alt="Content"
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            objectFit="cover"
          />
        )}
      </Box>
    </Box>
  );
};

export default BlockOne;
